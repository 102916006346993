/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on February 17, 2021 */

@font-face {
    font-family: 'abel';
    src: url('../../abel-regular-webfont.eot');
    src: url('../../abel-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../../abel-regular-webfont.woff2') format('woff2'),
         url('../../abel-regular-webfont.woff') format('woff'),
         url('../../abel-regular-webfont.ttf') format('truetype'),
         url('../../abel-regular-webfont.svg#abelregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
